import { Fragment, useState, useEffect } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  useParams,
  useSearchParams,
} from "react-router-dom";
import { universities } from "./universities";
import ReactPlayer from "react-player";
import Loader from "./Loader";
import { useForm, useFieldArray, Controller } from "react-hook-form";
import CustomLink from "./CustomLink";
import { useQueryParams } from "./useQueryParams";
import FAQ from "./FAQ";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const buildEmailInputPlaceholder = (emailDomains) => {
  return `avoid @${emailDomains.join(", @")}. @gmail recommended`;
};

export default function Home() {
  const [searchParams] = useSearchParams();
  const params = useParams();
  const { navigateWithAddedParams, navigateWithExistingParams } = useQueryParams();

  const universityId = params.university ?? "ucalgary";

  const courseComponentsEmailText = "We'll send you an email once all the sections you provided are available at the same time. "
  const courseComponentsSectionFormatText = <>Make sure you specify the section id <b>exactly</b> as it appears in the university's Schedule Builder.{universityId === "uoguelph" &&  " For example, a section 01 DE class would need to be formatted like DE01."}</>
  // const courseComponentsOpenSeatsText = <>If the section you are interested in currently has seats reserved for a group you are not part of,</>

  const [university, setUniversity] = useState(universities[universityId]);
  const [selectedTerm, setSelectedTerm] = useState(university.terms[0]);
  const [availableCourseComponents, setAvailableCourseComponents] = useState(
    Object.keys(university.inputPlaceholders.courseComponents)
  );
  const hasSingleSection = university.id === "uoguelph"
  const [isCheckoutLoading, setIsCheckoutLoading] = useState(false);
  // const [isOpen, setIsOpen] = useState(false);
  const [hasCheckoutError, setHasCheckoutError] = useState();
  const {
    control,
    register,
    handleSubmit,
    reset,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(university.formSchema),
    defaultValues: {
      courseComponents: [
        { componentId: "", componentType: availableCourseComponents[0] },
      ],
    },
  });
  

  const { fields, update, append, remove } = useFieldArray({
    control,
    name: "courseComponents",
  });

  useEffect(() => {
    if (params.university) {
      const university = universities[params.university];
      reset({
        course: "",
        courseComponents: [
          { componentId: "", componentType: Object.keys(university.inputPlaceholders.courseComponents)[0] },
        ],
      });
      setUniversity(university);
      setSelectedTerm(university.terms[0]);
      setAvailableCourseComponents(
        Object.keys(university.inputPlaceholders.courseComponents)
      );
    }
  }, [reset, params.university, setValue, getValues]);

  const onSubmit = async (data) => {
    setIsCheckoutLoading(true);

    const email = data.email.trim().toLowerCase();
    const course = data.course.trim().toUpperCase().split(" ");
    const subject = course[0];
    const courseNumber = course[1];
    const sections = data.courseComponents.map((c) => {
      const baseId = c.componentId.trim().toUpperCase();
      const section = university.id === "carleton" || university.id === "uoguelph" ? baseId : `${c.componentType}-${baseId}`;
      return section;
    });

    const registration = {
      email: email,
      university: university.id,
      term: selectedTerm.id,
      subject: subject,
      courseNumber: courseNumber,
      sections: sections,
    };

    try {
      const response = await fetch(
        process.env.REACT_APP_REGISTER_FUNCTION_URL,
        {
          method: "POST",
          body: JSON.stringify({
            // referee: searchParams.get("referee"),
            user: searchParams.get("u"),
            token: searchParams.get("t"),
            ...registration,
          }),
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      let responseData = await response.json();
      if (response.ok && !response.headers.has("X-Amz-Function-Error")) {
        navigateWithAddedParams(
          `/checkout/success?university=${university.id}&term=${
            selectedTerm.id
          }&subject=${subject}&courseNumber=${courseNumber}&sections=${sections.join(
            ","
          )}`
        );
      } else {
        if (responseData.errorCode === "NOT_MEMBER" || responseData.errorCode === "INVALID_TRIAL") {
          navigateWithExistingParams(`/r/${university.id}/pricing`, { state: registration });
        } else if (responseData.errorCode === "INVALID_REFERRAL") {
          setHasCheckoutError(
            "This referral is not valid. Either it has already been redeemed or the url is incorrect. Send us an email at opencoursenotifyme@gmail.com for further support"
          );
        }
      }
    } catch (e) {
      setHasCheckoutError(
        "Something went wrong please try again.  Send us an email at opencoursenotifyme@gmail.com if the problem persists"
      );
    }
    setIsCheckoutLoading(false);
  };

  const onErrors = (data) => {};

  return (
    <div>
    <div className="bg-[#FAF7F0] flex flex-col min-h-screen">
      <div className="relative isolate px-6 pt-14 lg:px-8">
        <div className="mx-auto max-w-2xl">
        {/* {university.id !== "ucalgary" && <h1 className="text-center text-xl md:text-2xl tracking-tight text-[#116A7B]">Completely free for a limited time!</h1>} */}
        <h1 className="text-center text-lg md:text-xl tracking-tight text-[#116A7B]">New Opportunity: Earn Money Tutoring Your Past Courses or Get Tutored by Top Students. <a href="https://forms.gle/9H8AamBqikCpGauL7" className="font-semibold text-black">Click Here</a></h1>
          <div className="text-center mt-5">
            <h1 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl">
              Get notified when a spot opens up
            </h1>
            <p className="mt-6 text-lg leading-8 text-gray-600">
              Tired of constantly checking if a spot opened up for your course?
              Our technology will regularly monitor the course's availability
              and send you an email when it changes!
            </p>
          </div>
          <div>
            <form onSubmit={handleSubmit(onSubmit, onErrors)}>
              <Listbox
                value={university}
                onChange={(e) => navigateWithExistingParams(`/r/${e.id}`)}
              >
                {({ open }) => (
                  <>
                    <Listbox.Label className="block text-sm font-medium leading-6 text-gray-900">
                      University
                    </Listbox.Label>
                    <div className="relative mt-2">
                      <Listbox.Button className="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-[#116A7B] sm:text-sm sm:leading-6">
                        <span className="flex items-center">
                          <span className="ml-3 block truncate">
                            {university.name}
                          </span>
                        </span>
                        <span className="pointer-events-none absolute inset-y-0 right-0 ml-3 flex items-center pr-2">
                          <ChevronUpDownIcon
                            className="h-5 w-5 text-gray-400"
                            aria-hidden="true"
                          />
                        </span>
                      </Listbox.Button>

                      <Transition
                        show={open}
                        as={Fragment}
                        leave="transition ease-in duration-100"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                      >
                        <Listbox.Options className="absolute z-10 mt-1 max-h-56 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                          {Object.values(universities).map((university) => (
                            <Listbox.Option
                              key={university.id}
                              className={({ active }) =>
                                classNames(
                                  active
                                    ? "bg-[#116A7B] text-white"
                                    : "text-gray-900",
                                  "relative cursor-default select-none py-2 pl-3 pr-9"
                                )
                              }
                              value={university}
                            >
                              {({ selected, active }) => (
                                <>
                                  <div className="flex items-center">
                                    <span
                                      className={classNames(
                                        selected
                                          ? "font-semibold"
                                          : "font-normal",
                                        "ml-3 block truncate"
                                      )}
                                    >
                                      {university.name}
                                    </span>
                                  </div>

                                  {selected ? (
                                    <span
                                      className={classNames(
                                        active
                                          ? "text-white"
                                          : "text-[#116A7B]",
                                        "absolute inset-y-0 right-0 flex items-center pr-4"
                                      )}
                                    >
                                      <CheckIcon
                                        className="h-5 w-5"
                                        aria-hidden="true"
                                      />
                                    </span>
                                  ) : null}
                                </>
                              )}
                            </Listbox.Option>
                          ))}
                        </Listbox.Options>
                      </Transition>
                    </div>
                  </>
                )}
              </Listbox>
              <div className="mt-3">
                <Listbox value={selectedTerm} onChange={setSelectedTerm}>
                  {({ open }) => (
                    <>
                      <Listbox.Label className="block text-sm font-medium leading-6 text-gray-900">
                        Term
                      </Listbox.Label>
                      <div className="relative mt-2">
                        <Listbox.Button className="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-[#116A7B] sm:text-sm sm:leading-6">
                          <span className="flex items-center">
                            <span className="ml-3 block truncate">
                              {selectedTerm.name}
                            </span>
                          </span>
                          <span className="pointer-events-none absolute inset-y-0 right-0 ml-3 flex items-center pr-2">
                            <ChevronUpDownIcon
                              className="h-5 w-5 text-gray-400"
                              aria-hidden="true"
                            />
                          </span>
                        </Listbox.Button>

                        <Transition
                          show={open}
                          as={Fragment}
                          leave="transition ease-in duration-100"
                          leaveFrom="opacity-100"
                          leaveTo="opacity-0"
                        >
                          <Listbox.Options className="absolute z-10 mt-1 max-h-56 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                            {university.terms.map((term) => (
                              <Listbox.Option
                                key={term.id}
                                className={({ active }) =>
                                  classNames(
                                    active
                                      ? "bg-[#116A7B] text-white"
                                      : "text-gray-900",
                                    "relative cursor-default select-none py-2 pl-3 pr-9"
                                  )
                                }
                                value={term}
                              >
                                {({ selected, active }) => (
                                  <>
                                    <div className="flex items-center">
                                      <span
                                        className={classNames(
                                          selected
                                            ? "font-semibold"
                                            : "font-normal",
                                          "ml-3 block truncate"
                                        )}
                                      >
                                        {term.name}
                                      </span>
                                    </div>

                                    {selected ? (
                                      <span
                                        className={classNames(
                                          active
                                            ? "text-white"
                                            : "text-[#116A7B]",
                                          "absolute inset-y-0 right-0 flex items-center pr-4"
                                        )}
                                      >
                                        <CheckIcon
                                          className="h-5 w-5"
                                          aria-hidden="true"
                                        />
                                      </span>
                                    ) : null}
                                  </>
                                )}
                              </Listbox.Option>
                            ))}
                          </Listbox.Options>
                        </Transition>
                      </div>
                    </>
                  )}
                </Listbox>
              </div>
              {errors.term && (
                <p className="mt-2 text-red-400">{errors.term.message}</p>
              )}
              <div className="mt-3">
                <label
                  htmlFor="course"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Course
                </label>
                <div className="relative mt-2 rounded-md shadow-sm">
                  <input
                    type="text"
                    name="course"
                    id="course"
                    className="block w-full rounded-md border-0 py-1.5 pl-3 lg:pl-5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#116A7B] sm:text-sm sm:leading-6"
                    placeholder={university.inputPlaceholders.course}
                    {...register("course")}
                  />
                </div>
                {errors.course && (
                  <p className="mt-2 text-red-400">{errors.course.message}</p>
                )}
              </div>
              <div className="mt-3">
                <label
                  htmlFor="courseComponents"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  {hasSingleSection ? "Section" : "Course Components"}
                </label>
                <p className="mt-2 mb-4 text-xs">
                {!hasSingleSection && courseComponentsEmailText}
                {courseComponentsSectionFormatText}
                </p>
                {universityId === "ucalgary" && <p className="mt-2 text-xs">

                
      {/* <span>{courseComponentsOpenSeatsText}</span>
      <span className="font-bold cursor-pointer w-4 inline ml-1 mb-1"onClick={()=>setIsOpen(true)}>read this.</span>       */}
  
      
   </p>
               }
       
                {fields.map((fieldGroup, index) => {
                  return (
                    <div key={fieldGroup.id} className="flex items-center mt-2">
                      {!hasSingleSection && <Controller
                
                        name={`courseComponents[${index}].componentType`}
                        control={control}
                        defaultValue={fieldGroup.componentType}
                        render={({ field }) => (
                          <Listbox
                            disabled={index !== fields.length - 1}
                            value={fieldGroup.componentType}
                            onChange={(value) => {
                              update(index, {
                                id: fieldGroup.id,
                                componentType: value,
                                componentId: fieldGroup.componentId,
                              });
                            }}
                          >
                            {({ open }) => (
                              <>
                                <Listbox.Button className="mr-2 relative w-30 cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-[#116A7B] sm:text-sm sm:leading-6">
                                  <span className="flex items-center">
                                    <span className="ml-3 block truncate">
                                      {field.value}
                                    </span>
                                  </span>
                                  <span className="pointer-events-none absolute inset-y-0 right-0 ml-3 flex items-center pr-2">
                                    <ChevronUpDownIcon
                                      className="h-5 w-5 text-gray-400"
                                      aria-hidden="true"
                                    />
                                  </span>
                                </Listbox.Button>

                                <Transition
                                  show={open}
                                  as={Fragment}
                                  leave="transition ease-in duration-100"
                                  leaveFrom="opacity-100"
                                  leaveTo="opacity-0"
                                >
                                  <Listbox.Options className="absolute z-10 mt-1 max-h-56 w-30 overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                    {availableCourseComponents.map((type) => (
                                      <Listbox.Option
                                        key={type}
                                        value={type}
                                        className={({ active }) =>
                                          classNames(
                                            active
                                              ? "bg-[#116A7B] text-white"
                                              : "text-gray-900",
                                            "relative cursor-default select-none py-2 pl-3 pr-9"
                                          )
                                        }
                                      >
                                        {({ selected, active }) => (
                                          <>
                                            <div className="flex items-center">
                                              <span
                                                className={classNames(
                                                  selected
                                                    ? "font-semibold"
                                                    : "font-normal",
                                                  "ml-3 block truncate"
                                                )}
                                              >
                                                {type}
                                              </span>
                                            </div>

                                            {selected ? (
                                              <span
                                                className={classNames(
                                                  active
                                                    ? "text-white"
                                                    : "text-[#116A7B]",
                                                  "absolute inset-y-0 right-0 flex items-center pr-4"
                                                )}
                                              >
                                                <CheckIcon
                                                  className="h-5 w-5"
                                                  aria-hidden="true"
                                                />
                                              </span>
                                            ) : null}
                                          </>
                                        )}
                                      </Listbox.Option>
                                    ))}
                                  </Listbox.Options>
                                </Transition>
                              </>
                            )}
                          </Listbox>
                        )}
                      />}
                      <Controller
                        name={`courseComponents[${index}].componentId`}
                        control={control}
                        defaultValue={fieldGroup.componentId}
                        render={({ field }) => (
                          <div className="relative rounded-md shadow-sm flex-1">
                            <input
                              type="text"
                              className="block w-full rounded-md border-0 py-1.5 pl-3 lg:pl-5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#116A7B] sm:text-sm sm:leading-6"
                              placeholder={
                                university.inputPlaceholders.courseComponents[
                                  fieldGroup.componentType
                                ]
                              }
                              {...field}
                            />
                          </div>
                        )}
                      />
                      {fields.length > 1 && (
                        <button
                          type="button"
                          className="h-full p-2 ml-2 text-gray-400 hover:text-gray-500 focus:outline-none"
                          onClick={() => {
                            const isLastAddedComponent =
                              index === fields.length - 1;
                            const elementToAdd = isLastAddedComponent
                              ? fields[index - 1].componentType
                              : fields[index].componentType;
                            setAvailableCourseComponents([
                              elementToAdd,
                              ...availableCourseComponents,
                            ]);
                            remove(index);
                          }}
                        >
                          <span className="sr-only">Close menu</span>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="currentColor"
                            className="w-6 h-6"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M6 18L18 6M6 6l12 12"
                            />
                          </svg>
                        </button>
                      )}
                    </div>
                  );
                })}
                {availableCourseComponents.length > 1 && (
                  <div className="text-center mt-4">
                    <button
                      type="button"
                      onClick={() => {
                        const lastAddedComponent = fields[fields.length - 1];
                        const selectableCourseComponents =
                          availableCourseComponents.filter(
                            (c) => c !== lastAddedComponent.componentType
                          );

                        setAvailableCourseComponents(
                          selectableCourseComponents
                        );
                        append({
                          componentType: selectableCourseComponents[0],
                          componentId: "",
                        });
                      }}
                      className="mb-1 text-center rounded-md px-2.5 py-1.5 text-xs font-semibold text-[#116A7B] outline-[#116A7B] shadow-sm hover:bg-[#116A7B] outline  hover:text-white hover:bg-[#116A7B]"
                    >
                      Add another component
                    </button>
                  </div>
                )}
                {errors.courseComponents && (
                  <p className="mt-2 text-red-400">
                    {
                      errors.courseComponents.find(
                        (item) => item?.componentId?.message
                      )?.componentId.message
                    }
                  </p>
                )}
              </div>

              <div className="mt-3">
                <label
                  htmlFor="course"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Your Email
                </label>
                <div className="relative mt-2 rounded-md shadow-sm">
                  <input
                    type="text"
                    name="email"
                    id="email"
                    className="block w-full rounded-md border-0 py-1.5 pl-3 lg:pl-5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#116A7B] sm:text-sm sm:leading-6"
                    placeholder={buildEmailInputPlaceholder(
                      university.emailDomains
                    )}
                    {...register("email")}
                  />
                </div>
                {errors.email && (
                  <p className="mt-2 text-red-400">{errors.email.message}</p>
                )}
              </div>
              <div className="mt-5">
                <button
                  disabled={isCheckoutLoading}
                  className="mb-1 text-center block w-full rounded-md bg-[#116A7B] px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-[#116A7B] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-[#116A7B]"
                  type="submit"
                >
                  {isCheckoutLoading ? <Loader /> : <> Continue</>}
                </button>
              </div>
              {hasCheckoutError && (
                <p className="mt-3 text-red-400">{hasCheckoutError}</p>
              )}
              <span className="mt-3 text-xs">
                By clicking Continue you agree to receiving emails and to the{" "}
                <CustomLink to="/termsofservice">
                  <button className="text-center text-[#116A7B] ">
                    Terms of Service
                  </button>
                </CustomLink>{" "}
                and the expectations defined within
              </span>
            </form>
          </div>
          <h2 className="text-center text-3xl sm:text-5xl font-bold tracking-tight text-gray-900 mt-10 mb-8">
            See it in action
          </h2>
          <ReactPlayer
            url={"/demo.mp4"}
            controls={true}
            width="100%"
            height="100%"
          />
                <FAQ university={university.id}/>
        </div>
      </div>
      <footer className="mt-auto px-6 pt-14 lg:px-8 pb-8">
        <div className="flex justify-between max-w-2xl mx-auto text-sm text-center text-[#116A7B]">
          <p>
          <CustomLink to="/contactus">
                  <button>
                    Contact Us
                  </button>
                </CustomLink>{" "}
          </p>
          <p>
          <CustomLink to="/privacypolicy">
                  <button>
                    Privacy Policy
                  </button>
                </CustomLink>{" "}
          </p>
        </div>
      </footer>
    </div>
    {/* <Transition
        show={isOpen}
        enter="transition duration-100 ease-out"
        enterFrom="transform scale-95 opacity-0"
        enterTo="transform scale-100 opacity-100"
        leave="transition duration-75 ease-out"
        leaveFrom="transform scale-100 opacity-100"
        leaveTo="transform scale-95 opacity-0"
      >
        <Dialog
          open={isOpen}
          onClose={() => setIsOpen(false)}
          className="fixed inset-0 z-50 overflow-y-auto"
        >
          <div className="flex items-center justify-center min-h-screen">
            <Dialog.Overlay className="fixed inset-0 bg-black opacity-30" />

            <div className="bg-white rounded-lg overflow-hidden shadow-xl transform transition-all sm:max-w-lg sm:w-full">

    
              <div className="p-4">
                <img alt="Schedule Builder showing section with available seats and a full waitlist" src="/fullclass.png"/>
              </div>
              <Dialog.Description className="p-4 border-b">
               You may come across a section like the one above that has available spots but has reserved seats you may not be a part of. Since there would be no way to know whether a student falls 
                Pay attention if the section you want to get into looks like this on Schedule Builder. Specifically, if the availability looks like the section highlighted in red where it says there are Seats available (e.g 205/238) yet the Waitlist has students on it or is full and you cannot enroll in the section. This can often be seen for classes with seats reserved for certain students, since technically there could be spots available but maybe not for you if you don't fall under the restricted category of students. <br/><br/>You are free to monitor such a section in this state but keep in mind your monitoring experience may differ from the expected behaviour such as by receiving a false positive notification. Until the restriction end date has passed or until the correct data is updated, this tool will recognize the available seats of sections like the one depicted since it is not possible to account for what restricted seats you specifically are a part of or any incorrect data from the university.
              </Dialog.Description>


              <div className="flex justify-end p-4 border-t">
                <button
                  onClick={() => setIsOpen(false)}
                  className="ml-4 text-gray-500"
                >
                  Got it
                </button>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition> */}
      </div>
  );
}
